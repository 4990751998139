if (navigator.onLine) {
    var online_index_cache_key = 'online_index';

    caches.open(online_index_cache_key).then(function(cache) {
        fetch('/', { cache: "reload" }).then((response) => {
            if (!response.ok) {
                throw new TypeError("bad response status");
            }
            cache.put('/', response);
            console.log(online_index_cache_key + ' is downloaded!');
        });
    });

}